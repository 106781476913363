header.main-header{
    // position: sticky;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // top: 0;
    padding: 0;
    height: 60px;
    background-color: rgba(255,255,255,1);
    box-shadow: 0px 2px 2px rgba(0,0,0,0.1);
    // box-shadow: 0 5px 5px rgba(0,0,0,0.3);
    margin-left: 0 !important;
    z-index: 999;
    nav.navbar-nav{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        a.logo{
            margin-left: 10px;
            @media screen and (max-width: 576px){
                display: none;
            }
        }
        .hamburger-btn{
            cursor: pointer;
            border-radius: 5px;
            &:hover{
                background-color: rgba(0,0,0,0.05);
            }
        }
        span.nav-title{
            font-size: 1.3rem;
            margin-bottom: 0;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .admin-menu{
            height: 100%;
            max-width: 250px;
            display: flex;
            align-items: center;
            padding: 0 10px;
            color: #888;
            cursor: pointer;
            &:hover{
                color: #555;
                background-color: rgba(0,0,0,0.05);
            }
            > .shop-logo{
                min-width: 36px;
                max-width: 36px;
                width: 36px;
                min-height: 36px;
                max-height: 36px;
                height: 36px;
                border: 2px solid #fff;
                box-shadow: 0 0 3px rgba(0,0,0,0.3);
                border-radius: 3px;
                background-color: #fff;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                margin-right: 10px;
            }
            > .name{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                @media screen and (max-width: 415px){
                    display: none;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .sidebar-mini.sidebar-collapse .main-header{
        margin-left: 0 !important;
    }
    .sidebar-mini.sidebar-collapse .main-sidebar{
        width: 250px !important;
        margin-left: -250px !important;
        box-shadow: none !important;
    }
    .sidebar-mini.sidebar-collapse.layout-fixed .brand-link{
        width: 250px !important;
    }
    .sidebar-mini.sidebar-collapse .nav-sidebar .nav-header{
        display: unset !important;
    }
    .sidebar-mini.sidebar-collapse .content-wrapper, .sidebar-mini.sidebar-collapse .main-footer, .sidebar-mini.sidebar-collapse .main-header{
        margin-left: 0 !important;
        transition: all 0.3s ease-in-out;
    }
}