.customer-reply-page{
    max-width: 576px;
    max-height: 500px;
    box-shadow: 1px 2px 3px rgba(0,0,0,0.2);
    border-radius: 5px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    .chat-header{
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 18px;
        background-color: #1C212F;
        color: #fff;
    }
}