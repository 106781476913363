.dashboard-page{
    .search-bar{
        margin-bottom: 20px;
        border-radius: 10px;
        background-color: #5589c5;
        color: #fff;
        .search-list{
            // margin: 10px 15px;
            display: flex;
            align-items: center;
            position: relative;
            &:first-child{
                border-bottom: 1px solid #fff;
            }
            i{
                width: 30px;
                text-align: center;
                // position: absolute;
                // left: 15px;
                // z-index: 0;
            }
            select.store-select{
                -webkit-appearance: none;
                appearance: none;
                background-color: transparent;
                color: #fff;
                font-size: 1rem;
                font-weight: bold;
                border: 0;
                width: 100%;
                padding: 10px 0;
                cursor: pointer;
                z-index: 1;
                option{
                    color: $text-dark;
                    font-weight: bold;
                    font-size: 0.9rem;
                }
            }
            input.date-select{
                -webkit-appearance: none;
                appearance: none;
                background-color: transparent;
                color: #fff;
                font-size: 1rem;
                font-weight: 100;
                font-family: $fontNumber;
                border: 0;
                width: 100%;
                padding: 10px 0;
                cursor: pointer;
                &:focus{
                    outline: none;
                }
            }
        }
    }
    // .stat-item{
    //     padding: 15px;
    //     margin-bottom: 20px;
    //     border-radius: 5px;
    //     color: #fff;
    //     &.stat-member{
    //         background-color: #d63f3f !important;
    //     }
    //     &.stat-reach{
    //         background-color: #4bad4b !important;
    //     }
    //     &.stat-customers{
    //         background-color: #547dd2 !important;
    //     }
    //     .stat-title{
    //         display: flex;
    //         align-items: center;
    //         margin-bottom: 10px;
    //         h6{
    //             font-weight: bold;
    //             margin-bottom: 0;
    //         }
    //         i{
    //             margin-left: 5px;
    //             cursor: help;
    //         }
    //     }
    //     .stat-number{
    //         font-size: 2.5rem;
    //     }
    // }
}