.user-team-page{
    margin: 0 auto;
    max-width: 768px;
    ul.team-list{
        position: relative;
        width: 100%;
        font-size: 1rem;
        overflow: hidden;
        background-color: #fff;
        border-radius: 5px;
        margin: 0 auto;
        box-shadow: 1px 2px 3px rgba(0,0,0,0.2);
        li{
            margin-left: 10px;
        }
        & > li {
            padding: 14px 5px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            &:last-child {
                border-bottom: 0;
            }
        }
        li.team-item{
            padding-top: 14px;
            overflow: hidden;
            .item-title{
                position: relative;
                &.has-list {
                    cursor: pointer;
                    &::after {
                        content: "\f078";
                        font-family: "font awesome 5 Pro";
                        font-weight: 200;
                        font-size: 16px;
                        position: absolute;
                        top: 0;
                        right: 20px;
                        transition: all 0.3s ease-in-out;
                        transform-origin: 50% 50%;
                        margin-left: 20px;
                    }
                    &.open {
                        &::after {
                            transform: rotate(180deg);
                        }
                    }
                    label {
                        cursor: pointer !important;
                    }
                }
                label {
                    width: calc(100% - 40px);
                    overflow: hidden;
                    text-overflow : ellipsis;
                    white-space: nowrap;
                    font-weight: normal;
                }
                i {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    text-align: center;
                    margin-right: 10px;
                    font-size: 24px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    &.level-1 {
                        background-image: url('../../images/web/user-06.png');
                    }
                    &.level-2 {
                        width: 36px;
                        background-image: url('../../images/web/user-07.png');
                    }
                    &.level-3 {
                        width: 29px;
                        background-image: url('../../images/web/user-08.png');
                    }
                    &.level-4 {
                        width: 31px;
                        background-image: url('../../images/web/user-09.png');
                    }
                    &.level-5 {
                        width: 31px;
                        background-image: url('../../images/web/user-10.png');
                    }
                }
            }
            .team-expand{
                max-height: 0;
                transition: max-height .25s cubic-bezier(0,1,0,1);
                overflow: hidden;
                &.expand{
                    max-height: 10000px;
                    transition: max-height 1s ease-in-out;
                }
            }
        }
    }
}