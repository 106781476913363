// Fonts
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC:100,300,400,500,700,900|Montserrat:400,700,200|Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@100;300&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Kodchasan:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kodchasan:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;600&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=News+Cycle:wght@400;700&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Baloo+Chettan+2:wght@400;600&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Martel+Sans:wght@300;400;600&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Baloo+Tamma+2:wght@400;600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam:wght@300;400;600&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaina+2:wght@400;500;600&display=swap');

// FontAwesome
@import '../../fonts/fontawesome/scss/fontawesome';

$Noto-Sans: 'Noto Sans TC', sans-serif;
$Default-font: 微軟正黑體,'Microsoft JhengHei', sans-serif;
$fontRoboto: 'Roboto', sans-serif;
$fontMPlus: 'M PLUS 1p', sans-serif;

// $fontNumber: 'Kodchasan', sans-serif;

// $fontNumber: 'Mulish', sans-serif;

// $fontNumber: 'Didact Gothic', sans-serif;

// $fontNumber: 'Baloo 2', cursive;

// $fontNumber: 'News Cycle', sans-serif;

// $fontNumber: 'Baloo Chettan 2', cursive;

// $fontNumber: 'Martel Sans', sans-serif;

// $fontNumber: 'Baloo Tamma 2', cursive;

$fontNumber: 'Be Vietnam', sans-serif;

// $fontNumber: 'Baloo Bhaina 2', cursive;