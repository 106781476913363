.back-to-top-btn{
    -webkit-apparance: none;
    appearance: none;
    min-width: 40px;
    max-width: 40px;
    width: 40px;
    min-height: 40px;
    max-height: 40px;
    height: 40px;
    border: 0;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.3);
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    &:focus{
        outline: none;
    }
    &:hover{
        background-color: rgba(0,0,0,0.6);
    }
    &.active{
        visibility: visible;
        opacity: 1;
    }
}