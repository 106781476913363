.upload-container{
    white-space: normal;
    .upload-images {
        display: inline-block;
        // margin: 10px 5px;
        padding: 60px;
        border: 1px dashed #d1d1d1;
        border-radius: 5px;
        text-align: center;
        font-size: 2.5em;
        cursor: pointer;
        position: relative;
        background-color: #EFEFEF;
        color: $text-secondary;
        img, i {
            max-width: 100%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
        &.hasImage{
            background-color: #fff;
            padding: 7px 7px 0 7px !important;
            border: 1px solid #d1d1d1;
            img{
                position: unset;
                transform: none;
                top: unset;
                left: unset;
            }
            .name{
                font-size: 14px;
                color: #555;
                font-family: Arial, Helvetica, sans-serif;
                font-weight: bold;
            }
            .size{
                font-size: 13px;
                color: #969696;
                line-height: 16px;
            }
        }
        &.error{
            opacity: 0.5;
        }
        .clear-image{
            position: absolute;
            top: -10px;
            right: -10px;
            width: 24px;
            height: 24px;
            background-color: rgba(0,0,0,0.4);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            &:hover{
                background-color: rgba(0,0,0,0.7);
            }
            &::before{
                content: "\f00d";
                font-family: "Font Awesome 5 Pro";
                color: #fff;
                font-weight: 100;
                font-size: 16px;
            }
        }
    }
    .error-alert{
        padding: 15px;
        background-color: $danger;
        color: #fff;
        font-size: 13px;
        border-radius: 5px;
        margin-top: 10px;
    }
}