@import url(https://fonts.googleapis.com/css?family=Noto+Sans+TC:100,300,400,500,700,900|Montserrat:400,700,200|Nunito&display=swap);
$Noto-Sans: 'Noto Sans TC', serif;
$Default-font: 'Microsoft JhengHei', sans-serif;

// bg color
$bg-gray5: #f5f5f5;
$bg-gray6: #f6f6f6;
$bg-gray7: #f7f7f7;
$main-bg: #207FA8;

$main-red: #D11C1C;
$main-color: #7ee4d0;
$secondary-color: #E9C62D;
$main-shadow: 0 0 3px rgba(0,0,0,0.2);

$text-dark: #323232;
$text-secondary: #969696;
$text-third: #DDDDDD;



// Variables

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #0085A1 !default;
$cyan:    #17a2b8 !default;

$primary:       $teal !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

// $Default-font: 'Microsoft JhengHei';