ul.stat-list{
    display: flex;
    flex-wrap: wrap;
    li.stat-item{
        margin-bottom: 10px;
        max-width: calc(25% - 10px);
        width: 25%;
        margin-right: 10px;
        &:last-child{
            margin-right: 0;
        }
        .react-reveal{
            background-color: #fff;
            border-radius: 5px;
            padding: 10px 15px;
            box-shadow: 1px 2px 3px rgba(0,0,0,0.05);
        }
        i{
            font-size: 30px;
            margin-right: 15px;
            width: 30px;
        }
        .amount{
            font-size: 30px;
            font-weight: bold;
            font-family: 微軟正黑體;
        }
        .label{
            margin-left: 47px;
            color: #969696;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        @media screen and (max-width:1200px) {
            flex: unset;
            margin-bottom: 10px;
            max-width: calc(50% - 10px);
            width: 50%;
            &:nth-child(even){
                margin-right: 0;
            }
        }
        @media screen and (max-width:576px) {
            flex: unset;
            margin-bottom: 10px;
            min-width: 100%;
            margin-right: 0;
        }
    }
}