.info-container{
    overflow: hidden;
    max-width: 768px;
    border-radius: 5px;
    margin: 0 auto 30px auto;
    box-shadow: 1px 2px 3px rgba(0,0,0,0.2);
    &.bg-red{
        .info-header{
            background-color: #c74949;
        }
        .info-body{
            background-color: #c74949;
        }
    }
    &.bg-green{
        .info-header{
            background-color: #43A047;
        }
        .info-body{
            background-color: #43A047;
        }
    }
    &.bg-blue{
        .info-header{
            background-color: #5589c5;
        }
        .info-body{
            background-color: #5589c5;
        }
    }
    .info-header{
        padding: 15px;
        background-color: #fff;
        h5.info-title{
            color: #fff;
            margin: 0;
            font-size: 1.5rem;
        }
    }
    .info-body{
        padding: 15px;
        background-color: #fff;
        .non-data{
            height: 150px;
            text-align: center;
            line-height: 150px;
            background-color: rgba(255,255,255,0.1);
            color: #fff;
            border-radius: 5px;
        }
        .info-img{
            margin: 0 auto 30px auto;
            width: 90%;
            max-width: 360px;
            img{
                border-radius: 20px;
                width: 100%;
            }
        }
        .info-item{
            display: flex;
            align-items: flex-start;
            i{
                width: 40px;
                text-align: center;
                color: #fff;
                font-size: 18px;
                margin-top: 4px;
            }
            .info-info{
                color: #fff;
                margin-bottom: 20px;
                line-height: 24px;
                letter-spacing: 1px;
                > label{
                    font-family: "微軟正黑體";
                    color: rgba(255,255,255,0.5);
                    margin-bottom: 5px;
                }
                .qrcode-btn{
                    padding: 6px 16px;
                    color: #fff;
                    background-color: rgba(255,255,255,0.1);
                    border-radius: 2px;
                    margin-right: 5px;
                    margin-bottom: 10px;
                    border: 0;
                    transition: all 0.1s ease-in-out;
                    &:hover{
                        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.12);
                    }
                    &:focus{
                        outline: none;
                    }
                }
            }
        }
    }
    .info-footer{
        padding: 0 15px;
        background-color: #fff;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }
}

.common-btn{
    border: 0;
    border-radius: 2px;
    background-color: transparent;
    padding: 6px 12px;
    transition: all 0.1s ease-in-out;
    font-size: 0.85rem;
    -webkit-appearance: none;
    appearance: none;
    &.table-btn{
        &.red{
            color: #f4433664;
            background-color: #f443361c;
        }
        &.black{
            color: #aaa;
            background-color: rgba(0,0,0,0.05) !important;
        }
        &.green{
            background-color: #43a0481d;
        }
    }
    &.red{
        color: #F44336;
        &:hover{
            background-color: #F44336;
            color: #fff;
            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.12);
        }
    }
    &.black{
        color: #666;
        &:hover{
            background-color: rgba(0,0,0,0.05);
            color: $text-dark;
            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.12);
        }
    }
    &.green{
        color: #43A047;
        &:hover{
            background-color:#43A047;
            color: #fff;
            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.12);
        }
    }
}