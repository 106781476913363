/*!
 * Global styling for this template
 */

.font-family-number{
    // font-family: $fontKodchasan;
    font-family: $fontNumber;
    // font-weight: 100;
}

@font-face{
    src:url(../fonts/genj/GenJ.ttf);
    font-family: genj;
}

.text-white{
    color: #fff !important;
}
.text-dark{
    color: $text-dark !important;
}
.text-secondary{
    color: $text-secondary !important;
}
.text-third{
    color: $text-third !important;
}
.text-main{
    color: $main-color !important;
}
.text-yellow{
    color: $main-color !important;
}
.text-yellow2{
    color: $secondary-color !important;
}
.text-red{
    color: $main-red !important;
}
.text-right{
    text-align: right !important;
}
.text-middle{
    text-align: center !important;
}
.text-left{
    text-align: left !important;
}

ul{
    margin: 0;
    padding: 0;
    li{
        list-style: none;
    }
}

label{
    margin: 0;
}

@for $i from 0 through 6 {
    .pd-#{$i}{
        padding: $i*5px !important;
    }
    .px-#{$i}{
        padding-right: $i*5px !important;
        padding-left: $i*5px !important;
    }
    .py-#{$i}{
        padding-top: $i*5px !important;
        padding-bottom: $i*5px !important;
    }
    .pt-#{$i}{
        padding-top: $i*5px !important;
    }
    .pr-#{$i}{
        padding-right: $i*5px !important;
    }
    .pb-#{$i}{
        padding-bottom: $i*5px !important;
    }
    .pl-#{$i}{
        padding-left: $i*5px !important;
    }
    .mg-#{$i}{
        margin: $i*5px !important;
    }
    .mx-#{$i}{
        margin-right: $i*5px !important;
        margin-left: $i*5px !important;
    }
    .my-#{$i}{
        margin-top: $i*5px !important;
        margin-bottom: $i*5px !important;
    }
    .mt-#{$i}{
        margin-top: $i*5px !important;
    }
    .mr-#{$i}{
        margin-right: $i*5px !important;
    }
    .mb-#{$i}{
        margin-bottom: $i*5px !important;
    }
    .ml-#{$i}{
        margin-left: $i*5px !important;
    }
}

@for $i from 0 through 6 {
    .letter-#{$i}{
        letter-spacing: $i*1px !important;
    }
}

// ::-webkit-scrollbar {
//     display: none;
// }

html{
    & > ::-webkit-scrollbar {
        display: none;
    }
    overflow: hidden;
}

html, body {
    height: 100% !important;
}

html.noscroll {
    position: fixed;
    /* overflow-y: scroll; */
    width: 100%;
}

body {
    font-family: genj, sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100% !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.8;
    color: #555;
    background-color: #f8f8f8;
}

.layout-container{
    width: 100%;
    height: 100%;
    padding-top: 60px;
}

.content-wrapper{
    // overflow-x: hidden;
    overflow-y: auto;
    // height: calc(100% - 60px);
    height: 100%;
    padding-bottom: 45px;
    background: #f8f8f8;
    .content-header{
        padding: 0 30px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        
        @media screen and (max-width: 768px){
            padding: 0 15px;
        }
    }
    section.content{
        padding: 15px 30px;
        @media screen and (max-width: 768px){
            padding: 15px;
        }
    }
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-track {
    margin: 5px 0;
}
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 100px;
    border-radius: 100px;
    background: rgb(159,159,162);
}


.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $Default-font;
}

.font-weight-bold{
    font-family: 微軟正黑體;
    font-weight: bold;
}

.font-size-xl{
    font-size: 1.1rem !important;
}
.font-size-lg{
    font-size: 1rem;
}
.font-size-md{
    font-size: 0.95rem;
}
.font-size-sm{
    font-size: 0.85rem;
}
.font-size-xs{
    font-size: 0.8rem;
}
.font-size-ss{
    font-size: 0.75rem;
}


a {
    &:link,
    &:visited,
    &:hover,
    &:active {
        text-decoration: none;
    }
}

input[type=text],
input[type=password],
input[type=tel],
textarea{
    -webkit-appearance: none;
	appearance: none;
}

select,
textarea{
    appearance: none;
    outline: none;
}

button {
    -webkit-appearance: none;
    appearance: none;
    &:focus{
        outline: none;
    }
}

input:disabled,
textarea:diabled {
    -webkit-text-fill-color: inherit;
    -webkit-opacity: 1;
    color: inherit;
}

.modal {
    // z-index: 1000;
}

.fixed-top {
    z-index: 9999;
}

.sticky {
    position: fixed;
    top: 54px;
    width: 100%;
}

#root {
    height: 100%;
}

.container-fluid {
    // max-width: 768px;
    // padding-left: 0;
    // padding-right: 0;
}

.custom-select {
    height: auto;
}

.user-btn-logout {
    color: #fff;
}

.pass-hidden {
    -webkit-text-security: disc;
}

.no-data {
    width: 100%;
    padding: 30px 0;
    text-align: center;
    color: #555;
    font-weight: bold;
    font-size: 0.9rem;
    // text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
    &::before {
        display: block;
		content: "";
		height: 120px;
		text-shadow: 2px 2px 4px rgba(0,0,0,0.7);
		background-image: url('../images/web/nodata.png');
		background-position: center;
		background-size: 100px 100px;
		background-repeat: no-repeat;
    }
}

body.swal2-iosfix {
    position: static !important;
}

#app {
    margin: 0 auto;
    position: relative;
    min-height: 100% !important;
    height: 100%;
    width: 100%;
    // max-width: 768px;
}

main {
    position: relative;
    top: 60px;
    bottom: 64px;
    height: calc(100% - 134px);
    overflow: hidden;
    overflow-y: auto;
    &.non-header{
        height: calc(100% - 74px);
        top: 0;
    }
    &.non-footer{
        height: calc(100% - 60px);
        bottom: 0;
    }
    &.non-header + &.non-footer {
        height: 100%;
        top: 0;
        bottom: 0;
    }
}

.full-height {

}

.absolute-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

// .page-container {
//     padding: 0.5rem;
//     background-color: white;
// }

.page-container {
    padding: 0 15px;
    overflow: hidden;
}
.avatar-upload{
    cursor: pointer;
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.4);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        border-radius: 50%;
    }
    &:after{
        content: '\f083';
        font-family: 'Font AweSome 5 Pro';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: white;
        font-size: 26px;
        opacity: 0;
    }
    &:hover:after, &:hover:before{
        opacity: 1;
    }
    #upload-avatar-input{
        position: absolute;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        opacity: 0;
        cursor: pointer;
    }
}

.avatar-img {
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    margin: auto;
    border: 1px solid #ebebeb;
    border-radius: 50%;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.copy-text{
    border: 0;
    background-color: transparent;
    color: $main-color;
    &:focus{
        color: $main-color;
        outline: none;
    }
    &:hover{
        color: $main-color;
    }
}

.common-transition{
    transition: all 0.3s;
}

.modal-box{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    visibility: hidden;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active{
        visibility: visible;
        background-color: rgba(0,0,0,0.55);
    }
}

.add-info-btn{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: fixed;
    right: 15px;
    bottom: 80px;
    background-color: #ffea00;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2);
    transition: all 0.3s ease-in-out;
    &:hover{
        a{
            i{
                transform: translate(-50%, -50%) rotate(180deg);
            }
        }
    }
    a{
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
        &:hover{
            span{
                display: block;
            }
        }
        i{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(0deg);
            color: #666;
            font-size: 22px;
            transition: all 0.3s ease-in-out;
        }
        span{
            display: none;
            position: absolute;
            top: 50%;
            left: -50px;
            transform: translateY(-50%);
            padding: 3px 7px;
            background: #666;
            color: #fff;
            font-weight: bold;
            border-radius: 2px;
        }
    }
}

.common-link{
    display: flex;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 25px;
    box-shadow: 1px 2px 3px rgba(0,0,0,0.2);
    > a, > div, > button{
        border: 0;
        background-color: transparent;
        display: block;
        flex: 1;
        padding: 10px 0;
        text-align: center;
        position: relative;
        &::before{
            content: '';
            position: absolute;
            width: 1.5px;
            height: 70%;
            background-color: rgba(0,0,0,0.2);
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        &:first-child{
            &::before{
                display: none;
            }
        }
        i,img{
            margin-bottom: 10px;
            width: 24px;
            font-size: 20px;
        }
    }
}

h5{
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 15px;
}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.tools-btn{
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 900 !important;
    > button{
        background-color: #ffea00;
        color: #555;
        &:hover{
            background-color: #f0dc00;
        }
    }
    .MuiSpeedDialAction-tooltipPlacementLeft{
        .MuiSpeedDialAction-staticTooltipLabel{
            background-color: #555;
            color: #fff;
            font-family: genj;
        }
    }
}

.pswp img {
    max-width: none;
    object-fit: contain;
}

.pswp__img--placeholder--blank{
    display: none !important;
}