.data-table{
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 0 15px;
    box-shadow: 0 2px 7px rgba(0,0,0,0.15);
    margin: 30px auto 0 auto;
    max-width: 992px;
    &:first-child{
        margin: 0 auto;
    }
    &.green{
        background-color: #329e74;
        h5.table-title{
            color: #fff;
        }
    }
    &.blue{
        background-color: #5589c5;
        h5.table-title{
            color: #fff;
        }
    }
    h5.table-title{
        font-size: 24px;
        margin: 15px 10px 15px 0;
        padding: 10px;
        border-radius: 5px;
    }
    input[type="checkbox"],
    input[type="radio"]{
        cursor: pointer;
        min-width: 20px;
        max-width: 20px;
        width: 20px;
        min-height: 20px;
        max-height: 20px;
        height: 20px;
        -webkit-appearance: none;
        appearance: none;
        border: 1px solid $text-secondary;
        border-radius: 3px;
        position: relative;
        padding: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        &:checked{
            &:before{
                content: '\f00c';
                font-family: 'Font Awesome 5 Pro';
                font-size: 14px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                color: #fff;
            }
            background-color: #D70C18;
            border: 1px solid transparent;
        }
        &:focus{
            outline: none;
        }
    }
    // input[type=checkbox]{
    //     cursor: pointer;
    //     width: 16px;
    //     height: 16px;
    //     display: block;
    //     -webkit-appearance: none;
    //     appearance: none;
    //     border: 1px solid $text-secondary;
    //     border-radius: 3px;
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%,-50%);
    //     &:checked{
    //         &:before{
    //             content: '\f00c';
    //             font-family: 'Font Awesome 5 Pro';
    //             font-size: 12px;
    //             position: absolute;
    //             top: 50%;
    //             left: 50%;
    //             transform: translate(-50%,-50%);
    //             color: $text-dark
    //         }
    //     }
    //     &:focus{
    //         outline: none;
    //     }
    // }
    .search-bar{
        background-color: #f2f2f2;
        padding: 0 10px;
        margin-top: 15px;
        border-radius: 5px;
        display: flex;
        // display: none;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        position: relative;
        overflow: hidden;
        height: 50.8px;
        transition: max-height 0.5s ease-in-out;
        @media screen and (max-width: 416px) {
            max-height: 50.8px;
            height: unset;
        }
        &.active{
            max-height: 310.4px;
        }
        @media screen and (min-width: 415px){
            height: unset;
            overflow: hidden;
            &.active{
                height: unset;
            }
        }
        h6{
            position: relative;
            @media screen and (max-width: 416px) {
                width: 100%;
                cursor: pointer;
                &:before, &:after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    width: 10px;
                    height: 1.5px;
                    transition: all 0.5s cubic-bezier(0.86, 0, 0.07, 1);
                    background-color: #555;
                }
                &:before{
                    right: 8.2px;
                    transform: translateY(-50%) rotate(35deg);
                }
                &:after{
                    right: 0;
                    transform: translateY(-50%) rotate(-35deg);
                }
                &.active{
                    &:before{
                        transform: translateY(-50%) rotate(-35deg);
                    }
                    &:after{
                        transform: translateY(-50%) rotate(35deg);
                    }
                }
            }
        }
        .search-input-group {
            position: relative;
            display: inline-block;
            min-width: 150px;
            margin: 5px 0;
            @media screen and(max-width: 415px){
                width: 100%;
            }
            &.keyword{
                margin-left: 15px;
                @media screen and(max-width: 415px){
                    margin-left: 0px;
                    margin: 20px 0;
                }
            }
            &.dropdown{
                margin-left: 15px;
                @media screen and(max-width: 415px){
                    margin: 20px 0 0 0;
                }
            }
            input.inputDate{
                width: 100%;
                min-height: 42px;
                border: 1px solid #d1d1d1;
                border-radius: 5px;
                transition: all 0.15s;
                padding: 3px 5px;
                position: relative;
                background-color: #fff;
                &:focus{
                    border: 1px solid $text-secondary;
                    outline: none;
                }
                @media screen and (max-width: 415px) {
                    width: 100%;
                }
                @media not all and (min-resolution:.001dpcm){
                    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
                        &:before{
                            content: attr(data-text);
                            color: $text-secondary;
                            position: absolute;
                            left: 5px;
                        }
                    }
                }
            }
            input.searchInput{
                width: 100%;
                height: 42px;
                border: 1px solid #d1d1d1;
                border-radius: 5px;
                transition: all 0.15s;
                padding: 3px 5px;
                &:focus{
                    border: 1px solid $text-secondary;
                    outline: none;
                }
                &::placeholder{
                    color: $text-secondary;
                }
            }
            select.searchSelect{
                height: 42px;
                border: 1px solid #d1d1d1;
                transition: all 0.15s;
                cursor: pointer;
                font-size: 0.9rem;
                &:focus{
                    outline: none;
                    box-shadow: none;
                    border: 1px solid $text-secondary;
                }
            }
            .search-input-cross{
                width: 16px;
                height: 16px;
                background-color: rgba(0,0,0,0.45);
                border-radius: 50%;
                position: absolute;
                top: 50%;
                right: 5px;
                transform: translateY(-50%);
                cursor: pointer;
                &.date{
                    right: 30px;
                    @media not all and (min-resolution:.001dpcm){
                        @supports (-webkit-appearance:none) and (stroke-color:transparent) {
                            right: 5px;
                        }
                    }
                }
                &:hover{
                    background-color: rgba(0,0,0,0.55);
                }
                &:before, &:after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 8px;
                    height: 1px;
                    border-radius: 3px;
                    background-color: #fff;
                }
                &:before{
                    transform: translate(-50%,-50%) rotate(45deg);
                }
                &:after{
                    transform: translate(-50%,-50%) rotate(135deg);
                }
            }        
        }
        .search-btn{
            display: inline-block;
            appearance: none;
            -webkit-appearance: none;
            border: 0;
            width: 42px;
            height: 42px;
            background-color: rgba(0,0,0,0.55);
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition:  all 0.15s;
            margin-left: 10px;
            @media screen and (max-width: 415px ){
                width: 100%;
                margin-left: 0;
                margin-bottom: 10px;
                &:before{
                    content: '搜尋' !important;
                    font-family: unset !important;
                    font-weight: unset !important;
                }
            }
            &:before{
                content: '\f002';
                font-size: 1rem;
                font-weight: 900;
                font-family: 'Font Awesome 5 Pro';
                color: #fff;
            }
            &:hover{
                background-color: rgba(0,0,0,0.65);
            }
            &:focus{
                outline: none;
            }
        }
    }
    .onepageshow-select{
        width: 40px;
        padding: 0 5px;
        border-radius: 3px;
        cursor: pointer;
        // background-color: #fff;
        border: 1px solid #d1d1d1;
    }
    .table-responsive{
        // border: 1px solid #ebebeb;
        // border-radius: 7px;
        & ::-webkit-scrollbar {
            display: block;
        }
        // &::-webkit-scrollbar-thumb{
        //     background-color: rgba(255,255,255,0.5);
        //     transition: all 0.15s ease-in-out;
        //     &:hover{
        //         background-color: rgba(255,255,255,0.8);
        //     }
        // }
        table{
            border: 1px solid transparent;
            margin-bottom: 0;
            border-collapse: separate;
            border-spacing: 0;
            >thead{
                background-color: #fff;
                >tr{
                    // background-color: rgba(0,0,0,0.05);
                    >th{
                        position: relative;
                        cursor: pointer;
                        transition: all 0.15s;
                        user-select: none;
                        white-space: nowrap;
                        padding: 12px 20px;
                        text-align: center;
                        border-bottom: 0px solid #dee2e6;
                        border-top: 1px solid #ebebeb;
                        font-size: 0.9rem;
                        font-family: 微軟正黑體;
                        &:first-child{
                            border-left: 1px solid #ebebeb;
                            border-top-left-radius: 7px;
                        }
                        &:last-child{
                            border-right: 1px solid #ebebeb;
                            border-top-right-radius: 7px;
                        }
                        i{
                            position: absolute;
                            top: 50%;
                            right: 5px;
                            transform: translateY(-50%);
                            font-size: 12px;
                            color: #bebebe;
                        }
                        &:hover{
                            background-color: rgba(0,0,0,0.05);
                        }
                    }
                }
            }
            >tbody{
                background-color: #fff;
                >tr{
                    // &:nth-of-type(odd){
                    //     background-color: transparent;
                    // }
                    &:hover{
                        background-color: rgba(0,0,0,0.05);
                    }
                    >td{
                        font-size: 0.85rem;
                        color: #555;
                        position: relative;
                        white-space: nowrap;
                        text-align: center;
                        vertical-align: middle;
                        &:first-child{
                            border-left: 1px solid #ebebeb;
                        }
                        &:last-child{
                            border-right: 1px solid #ebebeb;
                        }
                    }
                    &:last-child{
                        > td{
                            border-bottom: 1px solid #ebebeb;
                            &:first-child{
                                border-bottom-left-radius: 7px;
                            }
                            &:last-child{
                                border-bottom-right-radius: 7px;
                            }
                        }
                    }
                }
            }
        }
    }
    .page-nav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        position: relative;
        flex-wrap: wrap;
        @media screen and (max-width: 768px){
            flex-direction: column;
            align-items: center;
        }
        input.goto-page{
            // border: 1px solid $text-secondary;
            border: 0;
            border-radius: 0;
            border-bottom: 1.5px solid #d1d1d1;
            width: 60px;
            padding: 1px 5px;
            cursor: pointer;
            text-align: center;
            cursor: text;
            &:focus{
                border-bottom: 1.5px solid $secondary-color;
                outline: none;
            }
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
    -moz-appearance: textfield;
}