/*!
 * Font Awesome Pro 5.13.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
 @import 'variables';
 @import 'mixins';
 @import 'core';
 @import 'larger';
 @import 'fixed-width';
 @import 'list';
 @import 'bordered-pulled';
 @import 'animated';
 @import 'rotated-flipped';
 @import 'stacked';
 @import 'icons';
 @import 'screen-reader';
 
 @import 'brands';
 @import 'duotone';
 @import 'light';
 @import 'regular';
 @import 'solid';
 @import 'v4-shims';
 