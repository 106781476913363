/*!
 * Loader styling for this template
 */

 .loader {
    // display: none !important;
    transition: 0.5s;
    // &.open {
    //     display: block !important;
    // }
    .loader-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: auto;
        z-index: 9999;
        overflow: hidden;
        overflow-y: auto;
        .loader-box {
            padding: 20px;
            background-color: rgba(0,0,0, 0.8);
            border-radius: 10px;
            .loader-icon {
                border: 3px solid transparent;
                border-radius: 50%;
                border-top: 3px solid #fff;
                width: 30px;
                height: 30px;
                -webkit-animation: spin 1s linear infinite; /* Safari */
                animation: loader-spin 1s linear infinite;
            }
        }
    }
}

/* Safari */
@-webkit-keyframes loader-spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes loader-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}