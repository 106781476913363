.modal-box{
    &.qrcode-light-box{
        .light-box{
            .light-box-header{
                padding: 15px 0;
                text-align: center;
                font-size: 18px;
                background-color: #fff;
                font-weight: bold;
                font-family: "微軟正黑體";
            }
            .light-box-body{
                text-align: center;
                padding-bottom: 10px;
                background-color: #fff;
            }
            .light-box-footer{
                padding: 12px 0;
                width: 100%;
                background-color: #ffea00;
                text-align: center;
                cursor: pointer;
            }
        }
    }
}