.bid-process-page{
    ul.member-bid-step{
        list-style-type: none;
	    padding: 0 10px;
        li{
            position: relative;
            margin-left: 40px;
            padding: 10px;
            font-size: 15px;
            border-bottom: none;
            color: #A4A4A4;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            &::before{
                content: attr(data-step);
                position: absolute;
                display: inline-block;
                margin-left: -20px;
                width: 30px;
                height: 30px;
                line-height: 30px;
                border-radius: 50%;
                background: #c7dbfc;
                text-align: center;
                color: #FFFFFF;
                z-index: 2;
                left: 0;
                transform: translateX(-50%);
            }
            &::after{
                content: "";
                position: absolute;
                top: 25%;
                height: 95%;
                border-left: 1px solid #CCC;
                left: -20px;
            }
            &.active{
                color: #6a6a6a;
                &::before{
                    background: #4583fa;
                }
            }
            &:last-child{
                border-bottom: 0px solid #F8F8F8;
                &::after{
                    display: none;
                }
            }
            &:nth-of-type(2n){
                background: transparent;
            }
            .bid-step-title{
                // font-weight: bold;
            }
            .bid-step-info{
                font-size: 13px;
	            line-height: 18px;
            }
            .bid-btn{
                margin-top: 5px;
	            font-size: 14px;
            }
        }
    }
}

.modal-box {
    &.view-light-box {
        font-family: "Microsoft JhengHei", sans-serif;
        .light-box {
            .light-box-header {
                padding: 10px 0;
                width: 100%;
                text-align: center;
                font-weight: bold;
                font-size: 1.1rem;
            }
            .light-box-body {
                padding: 15px;
                .view-item {
                    display: flex;
                    font-size: 1rem;
                    margin-bottom: 15px;
                    > label {
                        width: 100px;
                        font-weight: bold;
                    }
                    > img {
                        max-width: 150px;
                        max-height: 200px;
                    }
                }
            }
        }
    }
}
