.hamburger-box{
    padding: 15px;
    cursor: pointer;
    position: relative;
    &.active{
        .hamburger-menu{
            @media screen and (max-width: 415px){
                span{
                    &:nth-child(1){
                        top: 5.5px;
                        transform: rotate(-45deg);
                        transition: all 0.3s ease;
                    }
                    &:nth-child(2){
                        opacity: 0;
                        transition: all 0.3s ease;
                    }
                    &:nth-child(3){
                        bottom: 5.5px;
                        transform: rotate(45deg);
                        transition: all 0.3s ease;
                    }
                }
            }
        }
    }
    .hamburger-menu{
        width: 14px;
        position: relative;
        span{
            display: block;
            width: 100%;
            border-radius: 3px;
            height: 1.5px;
            background-color: #555;
            transition: all .3s;
            position: relative;
            margin-top: 4px;
            &:first-child{
                margin-top: 0;
            }
            @media screen and (max-width: 415px){
                &:nth-child(1){
                    top: 0;
                }
                &:nth-child(2){
                    opacity: 1;
                }
                &:nth-child(3){
                    bottom: 0;
                }
            }
        }
    }
}