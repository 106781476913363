.snack{
    width: 100%;
    height: 100%;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    &.open{
        // opacity: 1;
        // visibility: visible;
        animation: snackbar 2s ease 1 forwards;
    }
    .snack-bar{
        border: 0;
        width: 300px;
        height: 50px;
        background-color: rgba(0,0,0,0.7);
        text-align: center;
        line-height: 50px;
        border-radius: 10px;
        color: white;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        &.bar-green{
            background-color: rgb(216, 236, 207);
        }
        &.bar-red{
            background-color: red;
        }
    }
}

@keyframes snackbar {
    0%{
        opacity: 0;
        visibility: visible;
    }
    15%{
        opacity: 1;
    }
    85%{
        opacity: 1;
    }
    99%{
        opacity: 0;
    }
    100%{
        opacity: 0;
        visibility: hidden;
    }
}
