i{
    &.common-icon{
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 30px;
        display: block;
        &.icon-sm{
            width: 20px;
        }
        &::after{
            content: "";
            display: block;
            padding-top: 100%;
        }
        &.huobi{
            background-image: url('../../images/icon/huobi.png');
        }
        &.binance{
            background-image: url('../../images/icon/binance.png');
        }
    }
}