.brand-store-edit-page{
    .time-range-container{
        .time-range{
            display: flex;
            align-items: center;
            margin-top: 10px;
            &:first-child{
                margin-top: 0;
            }
            .time-range-input{
                border: 0;
                background-color: #eee;
                color: #555;
                margin: 0 10px;
                border-radius: 30px;
                text-align: center;
                max-width: 80px;
                height: 30px;
                font-family: $fontNumber;
                cursor: pointer;
                &::placeholder{
                    color: #969696;
                }
            }
            i{
                color: #969696;
                cursor: pointer;
                &:hover{
                    color: #555;
                }
            }
        }
        button{
            background-color: rgba(0, 0, 0, 0.05);
            color: #969696;
            border: 0;
            padding: 4px 12px;
            border-radius: 2px;
            margin: 10px 10px 10px 0;
            transition: all 0.15s ease-in-out;
            &:hover{
                color: #555;
                box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.12);
            }
        }
    }
}

.modal-box{
    &.time-range-curtain-box{
        .curtain-box{
            .curtain-box-header{
                padding: 15px;
                font-family: 微軟正黑體;
                font-weight: bold;
            }
            .curtain-box-body{
                ul.curtain-box-list{
                    li.curtain-box-item{
                        padding: 10px 15px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        &:hover{
                            background-color: rgba(0,0,0,0.05);
                        }
                        &.active{
                            background-color: rgba(0,0,0,0.05);
                            .check{
                                background-color: #ffea00;
                                &::before{
                                    content: "\f00c";
                                    font-family: "Font Awesome 5 Pro";
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    color: #555;
                                    font-size: 20px;
                                    font-weight: 200;
                                }
                            }
                        }
                        .check{
                            background-color: rgba(0,0,0,0.1);
                            border-radius: 50%;
                            width: 36px;
                            height: 36px;
                            margin-right: 10px;
                        }
                        > img {
                            margin: 0 10px;
                            width: 36px;
                        }
                        > i {
                            width: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #555;
                            font-size: 24px;
                        }
                        .text{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            flex: 1;
                        }
                    }
                }
            }
            .curtain-box-footer{
                padding: 15px;
                text-align: right;
                > button {
                    margin-left: 10px;
                    border: 0;
                    background-color: transparent;
                }
            }
        }
    }
}