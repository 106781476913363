.tab-list{
    display: flex;
    background-color: #fff;
    align-items: center;
    padding: 6px 5px;
    min-height: 48px;
    max-height: 48px;
    overflow-x: scroll;
    overflow-y: unset;
    position: sticky;
    top: 0;
    z-index: 1;
    box-shadow: 0 1px 4px rgba(0,0,0,0.2);
    .tab-item{
        width: 90px;
        min-width: 90px;
        text-align: center;
        padding: 5px 7px;
        transition: all 0.1s ease-in-out;
        font-size: 0.85rem;
        cursor: pointer;
        flex: 1;
        position: relative;
        border: 0;
        &:before{
            content: '';
            position: absolute;
            border-bottom: 3px solid transparent;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: 90px;
            transition: all 0.1s ease-in-out;
        }
        &.active{
            font-size: 1rem;
            font-weight: bold;
            &:before{
                border-bottom: 3px solid $main-color;
            }
        }
    }
}



// @media (max-width: 599.95px) {
//     .MuiTabs-scrollButtonsDesktop{
//         display: flex !important;
//         justify-content: center !important;
//         align-items: center !important;
//     }
// }
.tabList-bar{
    position: fixed;
    top: 60px;
    width: 100%;
    max-width: 768px;
    z-index: 2;
    margin: auto;
    .MuiAppBar-colorDefault{
        background-color: #fff !important;
        box-shadow:  0 1px 4px rgba(0,0,0,0.2) !important;
        .MuiTabs-scrollable{
            padding: 0 5px;
            .MuiTab-textColorPrimary{
                font-size: 0.8rem !important;
                color: $text-secondary !important;
                width: 90px !important;
                min-width: 90px !important;
                max-width: unset !important;
                flex: 1 !important;
                font-weight: bold;
                font-family: $Default-font !important;
                transition: all 0.15s ease-in-out;
                padding: 5px 0 !important;
                &:focus{
                    outline: none;
                    color: $text-dark !important;
                }
                &:hover{
                    color: $text-dark !important;
                }
                &.Mui-selected{
                    font-size: 1rem !important;
                    color: $text-dark !important;
                }
            }
            .MuiTabs-indicator{
                background-color: transparent !important;
                bottom: 4px !important;
                height: 2.5px !important;
                &:before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 100%;
                    width: 90px;
                    background-color: $main-color;
                }
            }
        }
    }
}
