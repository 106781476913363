.brand-info-page{
    ul.album-list{
        display: flex;
        flex-wrap: wrap;
        &:after{
            content: "";
            flex-grow: 99999;
        }
        li.album-item{
            margin-right: 10px;
            margin-bottom: 10px;
            height: 90px;
            position: relative;
            flex-grow: 1;
            &:hover{
                .album-container{
                    .overlay-box{
                        bottom: 0;
                    }
                }
                .album-delete{
                    visibility: visible;
                }
            }
            .album-container{
                width: 100%;
                height: 100%;
                position: relative;
                overflow: hidden;
                > img {
                    min-width: 100%;
                    height: 90px;
                    object-fit: cover;
                }
                .overlay-box{
                    position: absolute;
                    bottom: -40px;
                    width: 100%;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(0,0,0,0.5);
                    transition: bottom 0.3s ease-in-out;
                }
            }
            .album-delete{
                visibility: hidden;
                position: absolute;
                top: -7px;
                right: -7px;
                width: 18px;
                height: 18px;
                background-color: rgba(0,0,0,0.4);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                cursor: pointer;
                &:hover{
                    background-color: rgba(0,0,0,0.7);
                }
                &::before{
                    content: "\f00d";
                    font-family: "Font Awesome 5 Pro";
                    color: #fff;
                    font-weight: 100;
                    font-size: 14px;
                }
            }
        }
    }
    ul.social-list, ul.brief-list{
        display: flex;
        flex-wrap: wrap;
        li.social-item, li.brief-item{
            max-width: 80px;
            margin-right: 15px;
            margin-bottom: 15px;
            text-align: center;
            position: relative;
            cursor: pointer;
            &:hover{
                .delete{
                    visibility: visible;
                }
            }
            img{
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
            .logo{
                width: 50px;
                height: 50px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgb(29, 161, 242);
                margin: 0 auto;
                &::before{
                    content: "\f08e";
                    font-family: "Font Awesome 5 Pro";
                    color: #fff;
                    font-size: 24px;
                    font-weight: 100;
                }
            }
            .name{
                font-size: 0.85rem;
                margin: 5px 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .delete{
                visibility: hidden;
                position: absolute;
                top: -3px;
                right: -3px;
                width: 18px;
                height: 18px;
                background-color: rgba(0,0,0,0.4);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                cursor: pointer;
                &:hover{
                    background-color: rgba(0,0,0,0.7);
                }
                &::before{
                    content: "\f00d";
                    font-family: "Font Awesome 5 Pro";
                    color: #fff;
                    font-weight: 100;
                    font-size: 14px;
                }
            }
        }
        .social-add-btn, .brief-add-btn{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: $text-secondary;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all 0.1s ease-in-out;
            &:hover{
                background-color: #666;
            }
            i{
                color: #fff;
                font-size: 22px;
            }
        }
    }
}

.modal-box{
    &.social-light-box{
        .light-box{
            max-width: 600px;
            .light-box-header{
                padding: 15px;
                background-color: #fff;
                h6{
                    font-size: 1.5rem;
                    font-weight: bold;
                }
            }
            .light-box-body{
                background-color: #fff;
                .form-group{
                    border-bottom: 0;
                    ul.icon-picker-list{
                        display: flex;
                        flex-wrap: wrap;
                        li.icon-picker-item{
                            width: 45px;
                            height: 45px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 15px;
                            margin-bottom: 15px;
                            opacity: 0.5;
                            cursor: pointer;
                            transition: all 0.2s ease-in-out;
                            &.active{
                                opacity: 1;
                                transform: scale(1.3);
                            }
                            &:hover{
                                opacity: 1;
                            }
                            img{
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .light-box-footer{
                padding: 15px;
                text-align: right;
                background-color: #fff;
                button{
                    -webkit-appearance: none;
                    appearance: none;
                    padding: 7px 10px;
                    border: 0;
                    font-weight: bold;
                    transition: all 0.15s ease-in-out;
                    color: $text-secondary;
                    &:hover{
                        color: $text-dark;
                    }
                    &:focus{
                        outline: none;
                    }
                }
                .cancel-btn{
                    background-color: transparent;
                }
                .confirm-btn{
                    background-color: #eee;
                }
            }
        }
    }
}

.modal-box{
    &.brief-light-box{
        .light-box{
            width: 80%;
            max-width: 600px;
            .light-box-header{
                padding: 15px;
                background-color: #fff;
                h6{
                    font-size: 1.5rem;
                    font-weight: bold;
                }
            }
            .light-box-body{
                background-color: #fff;
                .form-group{
                    border-bottom: 0;
                }
            }
            .light-box-footer{
                padding: 15px;
                text-align: right;
                background-color: #fff;
                button{
                    -webkit-appearance: none;
                    appearance: none;
                    padding: 7px 10px;
                    border: 0;
                    font-weight: bold;
                    transition: all 0.15s ease-in-out;
                    color: $text-secondary;
                    &:hover{
                        color: $text-dark;
                    }
                    &:focus{
                        outline: none;
                    }
                }
                .cancel-btn{
                    background-color: transparent;
                }
                .confirm-btn{
                    background-color: #eee;
                }
            }
        }
    }
}